export const FLAT_RATE = 'flatRate';
export const PERCENTAGE_OF_RENTAL = 'percentageOfRental';
export const USAGE_MULTIPLE = 'usageMultiple';

export const REQUIRED_UPFRONT = 'requiredUpfront';
export const REQUIRED_UPFRONT_OPTIONAL = 'requiredUpfrontOptional';


export const feeTypeMapper = {
    "deliveryFee": "Delivery Fee",
    "pickupFee": "Pickup Fee",
    "shippingFee": "Shipping Fee",
    "cleaningFee": "Cleaning Fee",
    "setupFee": "Setup Fee",
    "environmentalFee": "Environmental Fee",
    "fuelFee": "Fuel Fee",
    "usageFee": "Usage Fee",
    "accessoryFee": "Accessory Fee",
    "consumablesFee": "Consumables Fee",
};

export const urgencyFieldOptions = [
    { value: 'I need a reply ASAP', label: 'I need a reply ASAP' },
    { value: 'I can wait 24 hours', label: 'I can wait 24 hours' },
    { value: "I'm flexible", label: "I'm flexible" },
];

export const durations = [
    { value: 'flexible', label: 'Flexible' },
    { value: 'specific', label: 'Specific' }
];

export const FLEXIBLE_DURATION = 'flexible';
export const SPECIFIC_DURATION = 'specific';
export const durationTypes = [
    { value: 'Hours', label: 'Hours' },
    { value: 'Days', label: 'Days' },
]

export const FLEXIBLE_DATE = 'flexible';
export const SPECIFIC_DATE = 'specific'
export const dateOptions = [
    { value: "flexible", label: "Flexible" },
    { value: 'specific', label: 'Specific' }
]

export const deliverPickupOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No, you will pickup', value: 'no, you will pickup' },
    { label: 'No applicable', value: 'no applicable' }
]
export const DELIVERY_YES = 'yes';
export const DELIVERY_NO = 'no, you will pickup';
export const DELIVERY_NOT_APPLICABLE = 'no applicable';

export const radiusOptions = [
    { label: "5 km", value: '5 km' },
    { label: "10 km", value: '10 km' },
    { label: "15 km", value: '15 km' },
    { label: "20 km", value: '20 km' },
    { label: "25 km", value: '25 km' }
]

export const budgetOptions = [
    { label: "Firm", value: 'firm' },
    { label: "Flexible", value: 'flexible' },
    { label: "Range", value: "range" }
];

export const BUDGET_FIRM_OPTION = 'firm';
export const BUDGET_FLEXIBLE_OPTION  = 'flexible';
export const BUDGET_RANGE_OPTION = "range";

export const wayToConnectOptions = [
    {label:"Phone", value:'phone'},
    {label:"Email", value:'email'}
];

export const timeToConnectOptions = [
    {label:'Select best time to connect', value:""},
    { label: "8 AM - 12 PM", value: "8 AM - 12 PM" },
    { label: "12 PM - 4 PM", value: "12 PM - 4 PM" },
    { label: "4 PM - 8 PM", value: "4 PM - 8 PM" }
];