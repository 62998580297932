import React from 'react';
import PropTypes from 'prop-types';
import { IoIosStarHalf, IoIosStarOutline } from "react-icons/io";
import { IoStar } from "react-icons/io5";
import classNames from 'classnames';
import css from './Ratings.module.css'; 

const Ratings = (props) => {
    const { rating: ratingPerUser = 5, reviews: totalRatings, className } = props;
    const starArray = [1, 2, 3, 4, 5];

    // Calculate full stars and whether a half star is present
    const filledStars = Math.floor(ratingPerUser); // Whole number of filled stars
    const hasHalfStar = ratingPerUser % 1 !== 0;   // True if there's a fractional part (e.g., 3.5)
    
    return (
        <div className={classNames(className, css.ratingContent)}>
            {starArray.map(star => {
                // Render a half star if it's the next star after the filled ones and hasHalfStar is true
                if (star === filledStars + 1 && hasHalfStar) {
                    return <IoIosStarHalf className={css.starIconGold} key={star} />;
                }
                // Render filled stars for the first 'filledStars' amount
                if (star <= filledStars) {
                    return <IoStar className={css.starIconGold} key={star} />;
                }
                // Render empty stars for the rest
                return <IoIosStarOutline />                ;
            })}
        </div>
    );
};

Ratings.propTypes = {
    rating: PropTypes.number,  // Rating per user (default: 5)
    reviews: PropTypes.number, // Total number of reviews
};

export default Ratings;
