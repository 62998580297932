import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { createAlgoliaData, updaeAlgoliaListingsUser, updateAlgoliaData, userActiveListings } from '../../util/api';
import { UploadImage } from '../../util/cloudinary';
import moment from 'moment';


const filterUserPublicDataToAlgolia = (publicData = {}, removeables = []) => {
  const newObj = {};
  for (let key in publicData) {
    if (!removeables.includes(key)) {
      newObj[key] = publicData[key]
    }
  };
  return newObj;
}

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

export const PROFILE_SETTINGS_SAVED_NAME = 'app/ProfileSettingsPage/PROFILE_SETTINGS_SAVED_NAME';
export const PROFILE_SETTINGS_SAVED_PHONE = 'app/ProfileSettingsPage/PROFILE_SETTINGS_SAVED_PHONE';
export const PROFILE_SETTINGS_SAVED_ADDRESS = 'app/ProfileSettingsPage/PROFILE_SETTINGS_SAVED_ADDRESS';
export const PROFILE_SETTINGS_SAVED_BIO = 'app/ProfileSettingsPage/PROFILE_SETTINGS_SAVED_BIO';


// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const updateProfile = ({ imageFile, ...actionPayload }) => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        dispatch(updateProfileSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));

        // Dispatch actions for events
        const { firstName, lastName, bio, publicData, protectedData } = actionPayload || {};

        const { address } = publicData || {};
        const { phoneNumber } = protectedData || {};

        if (!!firstName && !!lastName) {
          dispatch({ type: PROFILE_SETTINGS_SAVED_NAME });
        };

        if (!!bio) {
          dispatch({ type: PROFILE_SETTINGS_SAVED_BIO })
        };

        if (!!address) {
          dispatch({ type: PROFILE_SETTINGS_SAVED_ADDRESS })
        };

        if (!!phoneNumber) {
          dispatch({ type: PROFILE_SETTINGS_SAVED_PHONE })
        };

        // Update user on Algolia
        let profileImage = null;
        if (!!imageFile) {
          console.log('uploading...')
          return UploadImage({ file: imageFile, id: actionPayload.profileImageId.uuid })
            .then(({ public_id, secure_url }) => {
              profileImage = { public_id, secure_url }
              return { profileImage, currentUser }
            })
        } else {
          return { profileImage, currentUser }
        }
      })
      .then(({ profileImage, currentUser }) => {
        const { email, createdAt } = currentUser.attributes;
        const {
          firstName,
          lastName,
          displayName,
          publicData: userPublicData = {},
        } = currentUser.attributes.profile;

        const profileImageMaybe = profileImage
          ? { profileImage }
          : userPublicData.profileImage
            ? { profileImage: userPublicData.profileImage }
            : {};


        userActiveListings(currentUser.id.uuid)
          .then((res) => {
            const { totalListings } = res.data || {};
            const totalListingsMaybe = totalListings ? { totalListings } : {};

            delete userPublicData.cart;
            delete userPublicData.profileImage;

            // User
            createAlgoliaData({
              indexName: process.env.REACT_APP_ALGOLIA_USERS_INDEX,
              objectID: currentUser.id.uuid,
              _geoloc:
                userPublicData.origin?.lng && userPublicData.origin?.lat
                  ? { lat: userPublicData.origin.lat, lng: userPublicData.origin.lng }
                  : {},
              firstName,
              lastName,
              displayName,
              email,
              publicData: { ...filterUserPublicDataToAlgolia(userPublicData, ['profileImage', 'cart']), ...totalListingsMaybe },
              ...profileImageMaybe,
              createdAt,
              timestamp:moment(createdAt).unix(), 
            })
            .then(() => {
              // Update listings to have updated user
              updaeAlgoliaListingsUser(currentUser.id.uuid)
              .catch(err => console.log('failed to Algolia listings user'))
            })
            .catch(err => console.log('failed to update Algolia user!'))

          

            // Update User to have uploaded profile image to cloudinary
            if (profileImage) {
              sdk.currentUser
                .updateProfile({ publicData: { profileImage } })
                .catch(err => console.log('failed to update profile'))
            };
          });
      })
      .catch(e => {
        console.log(e, 'ee')
        dispatch(updateProfileError(storableError(e)))
      });
  };
};
