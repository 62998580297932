import pick from 'lodash/pick';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getUserAchievements, replicateListing, transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { fetchExchangeRates } from '../../ducks/exchangeRates.duck';
import { generateAchievementReviews } from '../../util/reviews';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';
export const SHOW_LISTING_SUCCESS = 'app/ListingPage/SHOW_LISTING_SUCCESS';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const FETCH_AUTHOR_LISTINGS_REQUEST = 'app/ListingPage/FETCH_AUTHOR_LISTINGS_REQUEST';
export const FETCH_AUTHOR_LISTINGS_SUCCESS = 'app/ListingPage/FETCH_AUTHOR_LISTINGS_SUCCESS';
export const FETCH_AUTHOR_LISTINGS_ERROR = 'app/ListingPage/FETCH_AUTHOR_LISTINGS_ERROR';

export const REPLICATE_LISTING_REQUEST = 'app/ListingPage/REPLICATE_LISTING_REQUEST';
export const REPLICATE_LISTING_SUCCESS = 'app/ListingPage/REPLICATE_LISTING_SUCCESS';
export const REPLICATE_LISTING_ERROR = 'app/ListingPage/REPLICATE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,
  authorListingsInProgress: false,
  authorListingsError: null,
  authorListingsIds: [],
  replicateListingError: null,
  replicateListingInProgress: false,
  isVerified:false,
  totalListings:0
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };
    case SHOW_LISTING_SUCCESS:
      return { ...state };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload.reviews, isVerified:payload.emailVerified };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };
    case FETCH_AUTHOR_LISTINGS_REQUEST:
      return { ...state, authorListingsInProgress: true, authorListingsError: null };
    case FETCH_AUTHOR_LISTINGS_SUCCESS:
      return { ...state, authorListingsIds: payload.listingIds, totalListings:payload.totalListings, authorListingsInProgress: false };
    case FETCH_AUTHOR_LISTINGS_ERROR:
      return { ...state, authorListingsInProgress: false, authorListingsError: payload };

    case REPLICATE_LISTING_REQUEST:
      return {...state, replicateListingInProgress:true, replicateListingError:null};
    case REPLICATE_LISTING_SUCCESS:
      return {...state, replicateListingInProgress:false, replicateListingError:null};
    case REPLICATE_LISTING_ERROR:
      return {...state, replicateListingInProgress:false, replicateListingError:payload};

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const showListingSuccess = (listing) => ({ type: SHOW_LISTING_SUCCESS, payload: listing });

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = (reviews, emailVerified) => ({ type: FETCH_REVIEWS_SUCCESS, payload: {reviews, emailVerified} });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

export const fetchAuthorListingsRequest = () => ({ type: FETCH_AUTHOR_LISTINGS_REQUEST });
export const fetchAuthorListingsSuccess = (listingIds, totalListings) => ({ type: FETCH_AUTHOR_LISTINGS_SUCCESS, payload: {listingIds, totalListings} });
export const fetchAuthorListingsError = e => ({ type: FETCH_AUTHOR_LISTINGS_ERROR, payload: e });

export const replicateListingRequest = () => ({type:REPLICATE_LISTING_REQUEST});
export const replicateListingSuccess = () => ({type:REPLICATE_LISTING_SUCCESS});
export const replicateListingError = (e) => ({type:REPLICATE_LISTING_ERROR, payload:e});

// ================ Thunks ================ //

export const showListing = (listingId, config, isOwn = false) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));

  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      const listing = data.data.data;
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      dispatch(showListingSuccess(listing));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = (listingId, authorId) => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      // listing_id: listingId,
      subjectId:authorId,
      type:'ofProvider',
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);

      // Generate Custom reviews based on user's achievements.
      return getUserAchievements(authorId)
        .then(({ data }) => {
          const { emailVerified, stripeConnected, publishedListings, displayName } = data;
          const achievementReviews = generateAchievementReviews(emailVerified, stripeConnected, publishedListings, displayName);
          dispatch(fetchReviewsSuccess([...reviews, ...achievementReviews], emailVerified));
        });

    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (listing, message) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendInquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = (fetchLineItemsData) => dispatch => {
  dispatch(fetchLineItemsRequest());
  return transactionLineItems(fetchLineItemsData)
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
      return lineItems;
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        // listingId: listingId.uuid,
        // orderData,
      });
    });
};

export const fetchAuthorMoreListings = (authorId, currentListingId) => async (dispatch, getState, sdk) => {
  try {
    const params = {
      authorId,
      include: ['author', 'author.profileImage', 'images', 'currentStock'],
      perPage: 8,
      'fields.image': [
        // Scaled variants for large images
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Cropped variants for listing thumbnail images
        `variants.listing-card`,
        `variants.listing-card-2x`,
        `variants.listing-card-4x`,
        `variants.listing-card-6x`,

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      ...createImageVariantConfig(`listing-card`, 400, 1),
      ...createImageVariantConfig(`listing-card-2x`, 800, 1),
      ...createImageVariantConfig(`listing-card-4x`, 1600, 1),
      ...createImageVariantConfig(`listing-card-6x`, 2400, 1),
    };
    dispatch(fetchAuthorListingsRequest());
    const listingsRes = await sdk.listings.query({
      ...params
    });
    dispatch(addMarketplaceEntities(listingsRes));
    const authorListingsIds = listingsRes.data.data.map(l => l.id);
    const filteredIds = authorListingsIds.filter(id => id.uuid !== currentListingId);
    const totalListings = listingsRes.data.meta.totalItems;
    dispatch(fetchAuthorListingsSuccess(filteredIds, totalListings));
  } catch (e) {
    dispatch(fetchAuthorListingsError(storableError(e)));
    log.error(e, 'fetching-author-listings-failed', {
      authorId,
    });
  }
};

export const replicateListingThunk = (params) => (dispatch, getState) => {
  try {
    dispatch(replicateListingRequest());
    const { currentUser } = getState().user;
    if (!currentUser || !currentUser.id) {
      throw new Error('missing current user')
    };
    const {listingId, title, description} = params;
    const uId = currentUser.id.uuid;

    return replicateListing({listingId, title, description, currentUserId:uId})
    .then(res => {
       return res.data;
    })
    .catch(err => dispatch(replicateListingError(storableError(err))))
  } catch (err) {
     dispatch(replicateListingError(storableError(err)))
  }
};


export const loadData = (params, search, config) => (dispatch, getState) => {
  const listingId = new UUID(params.id);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }


  return dispatch(showListing(listingId, config))
    .then(response => {
      const listing = response?.data?.data;
      const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
      const authorId = listing?.relationships?.author?.data?.id?.uuid;
      const currentListingId = listing?.id?.uuid;

      if (isBookingProcessAlias(transactionProcessAlias)) {
        // Fetch timeSlots.
        // This can happen parallel to loadData.
        // We are not interested to return them from loadData call.
        fetchMonthlyTimeSlots(dispatch, listing);
      };

      // Fetch more listings by author.
      if (authorId) {
        dispatch(fetchAuthorMoreListings(authorId, currentListingId));
        dispatch(fetchReviews(listingId, authorId))
      }

      // Fetch exchange rates, do not fetch if already exists.
      const { exchangeRates } = getState().exchangeRate;

      if (typeof window != 'undefined' && exchangeRates && exchangeRates.length == 0) {

        dispatch(fetchExchangeRates());
      };

      return response;
    });
};

