import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
    LINE_ITEM_ACCESSORY_FEE,
    LINE_ITEM_CLEANING_FEE,
    LINE_ITEM_CONSUMABLES_FEE,
    LINE_ITEM_DELIVERY_FEE,
    LINE_ITEM_ENVIRONMENTAL_FEE,
    LINE_ITEM_FUEL_FEE,
    LINE_ITEM_SETUP_FEE,
    LINE_ITEM_USAGE_FEE,
    propTypes
} from '../../util/types';
import css from './OrderBreakdown.module.css';

// Define a mapping for the fee types and their corresponding labels
const ADDITIONAL_FEES_TYPES = {
    [LINE_ITEM_DELIVERY_FEE]: 'OrderBreakdown.deliveryFee',
    [LINE_ITEM_CLEANING_FEE]: 'OrderBreakdown.cleaningFee',
    [LINE_ITEM_SETUP_FEE]: 'OrderBreakdown.setupFee',
    [LINE_ITEM_ENVIRONMENTAL_FEE]: 'OrderBreakdown.environmentalFee',
    [LINE_ITEM_FUEL_FEE]: 'OrderBreakdown.fuelFee',
    [LINE_ITEM_USAGE_FEE]: 'OrderBreakdown.usageFee',
    [LINE_ITEM_ACCESSORY_FEE]: 'OrderBreakdown.accessoryFee',
    [LINE_ITEM_CONSUMABLES_FEE]: 'OrderBreakdown.consumablesFee',
};

const LineItemAdditionalFees = ({ lineItems, intl }) => {
    return (
        <>
            {lineItems && lineItems.length ? lineItems
                .filter(item => ADDITIONAL_FEES_TYPES[item.code] && !item.reversal)
                .map(feeItem => (
                    <div key={feeItem.code} className={css.lineItem}>
                        <span className={css.itemLabel}>
                            <FormattedMessage id={ADDITIONAL_FEES_TYPES[feeItem.code]} />
                        </span>
                        <span className={css.itemValue}>{formatMoney(intl, feeItem.lineTotal)}</span>
                    </div>
                )) : null}
        </>
    );
};

LineItemAdditionalFees.propTypes = {
    lineItems: propTypes.lineItems.isRequired,
    intl: intlShape.isRequired,
};

export default LineItemAdditionalFees;