import React from 'react';
import { array, object, string } from 'prop-types';
import { types } from '../../../../../util/sdkLoader';

// import { staticExchangeRates } from '../../config/configStripe';
import {
  FormattedMessage,
  intlShape,
  injectIntl,
} from '../../../../../util/reactIntl';
import { displayPrice } from '../../../../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../../../../util/uiHelpers';
import { convertPrice, formatMoney } from '../../../../../util/currency';
import { createSlug } from '../../../../../util/urlHelpers';
import { isBookingProcessAlias } from '../../../../../transactions/transaction';

import {
  AspectRatioWrapper,
  NamedLink,
  Ratings,
  ResponsiveImage,
} from '../../../../../components';
import { useConfiguration } from '../../../../../context/configurationContext';
import { createResourceLocatorString } from '../../../../../util/routes';
import css from './ListCard.module.css';

const { UUID } = types;

const TWENTY_EIGHT_DAYS = 'twentyEightDaysPrice';
const WEEK_PRICE = 'oneWeekPrice';

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, {
  loadAfterInitialRendering: 3000,
});

const PriceMaybe = props => {
  const {
    price,
    listingType,
    defaultPriceKey,
    transactionProcessAlias,
    config,
    intl,
    selectedCurrency,
  } = props;

  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(
    conf => conf.listingType === listingType
  );
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(
    price,
    selectedCurrency,
    intl
  );

  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage
            id="ListingCard.perUnit"
            values={{ defaultPriceKey: defaultPriceKey || 'perDayPrice' }}
          />
          <span className={css.seeMore}><FormattedMessage id='ListingCard.seeMore'/></span>
        </div>
      ) : null}
    </div>
  );
};

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text; // Return the original text if it's within or equal to the maxLength
  } else {
    return text.slice(0, maxLength) + '...'; // Truncate the text and add ellipsis
  }
}

export const ListCardComponent = props => {
  const config = useConfiguration();
  const {
    rental = {},
    intl,
    routes,
    history,
    currentUserCurrency,
    exchangeRates,
    onClick,
    queryID,
    additional,
    onActivateListing,
  } = props;

  const id = rental.objectID;
  const {
    title = '',
    price,
    user: { displayName, publicData:uPublicData },
    publicData,
  } = rental;

  const { storeName, rating } = uPublicData || {};
  

  const {
    category,
    city,
    listingType,
    defaultPriceKey,
    transactionProcessAlias,
    listingImages = [],
    prices,
  } = publicData || {};

  const { aspectWidth = 1, aspectHeight = 1 } = config.layout.listingImage;

  const slug = createSlug(title);

  const convertedPrice = !!price
    ? convertPrice(
        exchangeRates,
        price.amount,
        price.currency,
        currentUserCurrency
      )
    : null;

  const queryString =
    !!category && !!city && !!queryID
      ? `?city=${city.toLowerCase()}&category=${category.toLowerCase()}&queryID=${queryID}`
      : !!category && !!queryID
      ? `?category=${category.toLowerCase()}&queryID=${queryID}`
      : !!city && !!queryID
      ? `?city=${city.toLowerCase()}&queryID=${queryID}`
      : !!queryID
      ? `?queryID=${queryID}`
      : null;

  const firstImage = listingImages[0];
  const firstImageMaybe =
    !!firstImage && firstImage.secure_url ? (
      <img
        className={css.imageWrapper}
        src={firstImage.secure_url}
        alt={slug}
      />
    ) : (
      <div className={css.noImageWrapper}>
        <svg
          color="#333"
          width="100px"
          height="100px"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.828 5l-1-1H22v15.172l-1-1v-.69l-3.116-3.117-.395.296-.714-.714.854-.64a.503.503 0 0 1 .657.046L21 16.067V5zM3 20v-.519l2.947-2.947a1.506 1.506 0 0 0 .677.163 1.403 1.403 0 0 0 .997-.415l2.916-2.916-.706-.707-2.916 2.916a.474.474 0 0 1-.678-.048.503.503 0 0 0-.704.007L3 18.067V5.828l-1-1V21h16.172l-1-1zM17 8.5A1.5 1.5 0 1 1 15.5 7 1.5 1.5 0 0 1 17 8.5zm-1 0a.5.5 0 1 0-.5.5.5.5 0 0 0 .5-.5zm5.646 13.854l.707-.707-20-20-.707.707z" />
          <path fill="none" d="M0 0h24v24H0z" />
        </svg>
      </div>
    );

  const handleClick = () => {
    onClick();
    history.push(
      createResourceLocatorString(
        'ListingPage',
        routes,
        { id, slug },
        { search: queryString }
      )
    );
  };

  const setActivePropsMaybe = onActivateListing
    ? {
        onMouseEnter: () => onActivateListing(new UUID(id)),
        onMouseLeave: () => onActivateListing(null),
      }
    : {};

  return (
    <div className={css.listCard} onClick={handleClick}>
      {/* {additional ? <div className={css.additional}>Additional</div> : null} */}
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        {firstImageMaybe}
      </AspectRatioWrapper>
      <div className={css.info}>
        <PriceMaybe
          price={convertedPrice}
          listingType={listingType}
          transactionProcessAlias={transactionProcessAlias}
          defaultPriceKey={defaultPriceKey}
          config={config}
          intl={intl}
          selectedCurrency={currentUserCurrency}
        />
        <div className={css.mainInfo}>
          <div className={css.title}>{truncateText(title, 43)}</div>

          <div className={css.authorInfo}>
            <FormattedMessage
              id="ListCard.author"
              values={{ authorName: storeName || displayName }}
            />
            <Ratings className={css.rating} rating={rating}/>
          </div>
        </div>
      </div>
    </div>
  );
};

ListCardComponent.propTypes = {
  intl: intlShape.isRequired,
  rental: object.isRequired,
  currentUserCurrency: string.isRequired,
  exchangeRates: array.isRequired,
};

export default injectIntl(ListCardComponent);
