/**
 * Usage without sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w" />
 *
 * Usage with sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *     sizes="(max-width: 600px) 100vw, 50vw"
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w"
 *     sizes="(max-width: 600px) 100vw, 50vw" />
 *
 *   // This means that below 600px image will take as many pixels there are available on current
 *   // viewport width (100vw) - and above that image will only take 50% of the page width.
 *   // Browser decides which image it will fetch based on current screen size.
 *
 * NOTE: for all the possible image variant names and their respective
 * sizes, see the API documentation.
 */

import React from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import { AdvancedImage, responsive } from '@cloudinary/react';
import { fit } from '@cloudinary/url-gen/actions/resize';
import { H4 } from '../Heading/Heading';
import css from './ResponsiveImage.module.css';
import IconClose from '../IconClose/IconClose';


const ResponsiveImage = props => {
  const {
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    variants,
    dimensions,
    abbreviatedName = '',
    aspectHeight=1,
    aspectWidth=1,
    showRemoveIcon,
    onRemoveImage,
    isResponsiveImage=false,
    cloudinaryImage,
    inProgress,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const removeButton = showRemoveIcon ? <button className={css.removeImage} type='button' onClick={onRemoveImage}><IconClose size="large" /></button> : null;

  if (cloudinaryImage) {
    const myImage = isResponsiveImage ? cloudinaryImage.resize(fit()) : cloudinaryImage;
    return (
      <div className={css.rootImgURL}>
        {isResponsiveImage ? <AdvancedImage height={'200px'} width={'200px'} cldImg={myImage} plugins={[responsive({ steps: 200 })]} /> :
          <img alt={alt} className={css.rootForImage} srcSet={cloudinaryImage} />}
        {removeButton}
      </div>
    )
  }

  if (image == null || variants.length === 0) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);

    // const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;


    return (
      // <div className={noImageClasses}>
      //   <div className={css.noImageWrapper}>
      //     <NoImageIcon className={css.noImageIcon} />
      //     <div className={css.noImageText}>{noImageMessageText}</div>
      //   </div>
      // </div>
      <div className={noImageClasses}>
        <H4 className={css.noImage}>{abbreviatedName}</H4>
      </div>
    );
  }

  const imageVariants = image.attributes.variants;

  const srcSet = variants
    .map(variantName => {
      const variant = imageVariants[variantName];

      if (!variant) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      return `${variant.url} ${variant.width}w`;
    })
    .filter(v => v != null)
    .join(', ');

  const imgProps = {
    className: classes,
    srcSet,
    ...rest,
  };

  return <img alt={alt} {...imgProps} onContextMenu={e => e.preventDefault()} />;
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  sizes: null,
  noImageMessage: null,
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: oneOfType([propTypes.image, propTypes.imageAsset]),
  variants: arrayOf(string).isRequired,
  sizes: string,
  noImageMessage: string,
};

export default ResponsiveImage;