

import { v4 as uuidv4 } from 'uuid';
import { UploadImage } from '../util/cloudinary';
import { storableError } from '../util/errors';
import { deleteImage, sendEmailToSearchTeam } from '../util/api';

// ================ Action types ================ //

export const SEARCH_TEAM_HELP_REQUEST = 'app/useSearchTeam/SEARCH_TEAM_HELP_REQUEST';
export const SEARCH_TEAM_HELP_SUCCESS = 'app/useSearchTeam/SEARCH_TEAM_HELP_SUCCESS';
export const SEARCH_TEAM_HELP_ERROR = 'app/useSearchTeam/SEARCH_TEAM_HELP_ERROR';

export const IMAGE_UPLOAD_REQUEST = 'app/useSearchTeam/IMAGE_UPLOAD_REQUEST';
export const IMAGE_UPLOAD_SUCCESS = 'app/useSearchTeam/IMAGE_UPLOAD_SUCCESS';
export const IMAGE_UPLOAD_ERROR = 'app/useSearchTeam/IMAGE_UPLOAD_ERROR';

export const IMAGE_REMOVE_REQUEST = 'app/useSearchTeam/IMAGE_REMOVE_REQUEST';
export const IMAGE_REMOVE_SUCCESS = 'app/useSearchTeam/IMAGE_REMOVE_SUCCESS';
export const IMAGE_REMOVE_ERROR = 'app/useSearchTeam/IMAGE_REMOVE_ERROR';

// ================ Reducer ================ //

const initialState = {
    requestSearchTeamHelpInProgress: false,
    requestSearchTeamHelpError: null,
    imageUploadInProgress: false,
    imageUploadError: null,
    image: null,
    imageRemoveInProgress:false,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_TEAM_HELP_REQUEST:
            return { requestSearchTeamHelpInProgress: true, requestSearchTeamHelpError: null };
        case SEARCH_TEAM_HELP_SUCCESS:
            return { requestSearchTeamHelpInProgress: false, requestSearchTeamHelpError: null, image:null };
        case SEARCH_TEAM_HELP_ERROR:
            return { requestSearchTeamHelpInProgress: false, requestSearchTeamHelpError: payload };
        case IMAGE_UPLOAD_REQUEST:
            return { imageUploadInProgress: true, imageUploadError: null };
        case IMAGE_UPLOAD_SUCCESS:
            return { imageUploadInProgress: false, imageUploadError: null, image: payload };
        case IMAGE_UPLOAD_ERROR:
            return { imageUploadInProgress: false, imageUploadError: payload };
        case IMAGE_REMOVE_REQUEST:
            return { imageRemoveInProgress: true, imageRemoveError: null };
        case IMAGE_REMOVE_SUCCESS:
            return { imageRemoveInProgress: false, imageRemoveError: null, image: null };
        case IMAGE_REMOVE_ERROR:
            return { imageRemoveInProgress: false, imageRemoveError: payload }
        default:
            return state;
    }
}

// ================ Action creators ================ //

export const searchTeamHelpRequest = () => ({ type: SEARCH_TEAM_HELP_REQUEST });
export const searchTeamHelpSuccess = () => ({ type: SEARCH_TEAM_HELP_SUCCESS });
export const searchTeamhelpError = (e) => ({ type: SEARCH_TEAM_HELP_ERROR, payload: e });


export const uploadImageRequest = () => ({ type: IMAGE_UPLOAD_REQUEST });
export const uploadImageSuccess = (image) => ({ type: IMAGE_UPLOAD_SUCCESS, payload:image });
export const uploadImageError = (e) => ({ type: IMAGE_UPLOAD_ERROR, payload: e });

export const removeImageRequest = (publicId) => ({ type: IMAGE_REMOVE_REQUEST, payload:publicId });
export const removeImageSuccess = (publicId) => ({ type: IMAGE_REMOVE_SUCCESS, payload:publicId });
export const removeImageError = (e) => ({ type: IMAGE_REMOVE_ERROR, payload: e });

// ================ Thunks ================ //

export const useSearchTeam = (data) => (dispatch, getState, sdk) => {
    dispatch(searchTeamHelpRequest())
    return sendEmailToSearchTeam(data)
        .then(res => dispatch(searchTeamHelpSuccess()))
        .catch(error => dispatch(searchTeamhelpError({ error: storableError(error) })))
};

export const useSearchTeamUploadImage = (file) => (dispatch, getState, sdk) => {
    dispatch(uploadImageRequest())
    UploadImage({ id: uuidv4(), file })
        .then(({ public_id, secure_url }) => {
            console.log({public_id, secure_url});
            dispatch(uploadImageSuccess({ public_id, secure_url }))
        })
        .catch(e => dispatch(uploadImageError({ error: storableError(e) })))
};

export const useSearchTeamRemoveImage = publicId => dispatch => {
    dispatch(removeImageRequest(publicId));
  
    // Remove image from the cloudinary and locally;
    deleteImage({ publicId })
      .then(r => {
        dispatch(removeImageSuccess(publicId));
      })
      .catch(err => {
        dispatch(removeImageError({error:storableError(err)}));
      });
  };