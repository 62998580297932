import React, { useState, useEffect } from 'react';
import IconCollection from '../IconCollection/IconCollection';
import NamedLink from '../NamedLink/NamedLink';
import classNames from 'classnames';
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import Avatar from '../Avatar/Avatar';
import css from './MobileBottomNavbar.module.css';


const MobileBottomNavbar = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const state = useSelector(state => state);
    const location = useLocation();

    const { currentUser } = state.user;
    const { isAuthenticated } = state.auth;

    const isLoggedIn = isAuthenticated && currentUser?.id;


    const controlNavbar = () => {
        const threshold = 20; // Adjust this value to control when the navbar reappears during upward scroll
        const topThreshold = 20; // Adjust this value to define "near the top" area
        const isScrollingDown = window.scrollY > lastScrollY;
        const isScrollingUp = window.scrollY < lastScrollY - threshold;
        const isNearTop = window.scrollY < topThreshold;
    
        if (isNearTop) {
            // Always show the navbar when near the top of the page
            setIsVisible(true);
        } else if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
            // Hide the navbar when at the bottom of the page
            setIsVisible(false);
        } else if (isScrollingDown) {
            // Hide the navbar during downward scrolls
            setIsVisible(false);
        } else if (isScrollingUp) {
            // Show the navbar during significant upward scrolls
            setIsVisible(true);
        }
    
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY]);

    return (
        <div className={classNames(css.navbar, isVisible ? css.visible : css.hidden)}>
            <NamedLink className={classNames(css.navItem)} name="LandingPage">
                <div className={css.iconWrapper}><IconCollection name="homeIcon" /></div>
                <div className={css.navLink}>Home</div>
            </NamedLink>
            <NamedLink className={classNames(css.navItem, location?.pathname.includes("/listing") && css.active)} name="NewListingPage" to={{search:'?mobilemenu=open'}}>
                <div className={css.iconWrapper}><IconCollection name="createListing" /></div>
                <div className={css.navLink}>Create Listing </div>
            </NamedLink>
            {isLoggedIn ? (<NamedLink className={classNames(css.navItem, location?.pathname.includes("/inbox") && css.active)} name="InboxBasePage">
                <div className={classNames(css.iconWrapper, css.messageIcon)}><IconCollection name="myBooking" /></div>
                <div className={css.navLink}>My Bookings</div>
            </NamedLink>) : (<NamedLink className={classNames(css.navItem, location?.pathname.includes("/page/about") && css.active)} name="CMSPage" params={{ pageId: 'about' }}>
                <div className={classNames(css.iconWrapper, css.messageIcon)}><IconCollection name="myBooking" /></div>
                <div className={css.navLink}>About Us</div>
            </NamedLink>)}
            {isLoggedIn ? (<Avatar
                className={css.avatar}
                user={currentUser}
            />) : <NamedLink className={classNames(css.navItem, location?.pathname.includes("/signup") && css.active)} name="SignupPage" to={{search:'?mobilemenu=open'}}>
                <div className={css.iconWrapper}><IconCollection name="JOIN_NOW" /></div>
                <div className={css.navLink}>Join</div>
            </NamedLink>}
        </div>
    );
};

export default MobileBottomNavbar;