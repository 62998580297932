import moment from 'moment';
import { denormalisedResponseEntities } from '../util/data';
import { storableError } from '../util/errors';
// import { createImageVariantConfig } from '../util/sdkLoader';
import { v4 as uuidv4 } from 'uuid';
import { createImageVariantConfig } from '../util/sdkLoader';
// const { types } = require('sharetribe-flex-sdk');
// const { Money } = types;

const getItemsByAuthor = (cart = [], sdk) => {
  const queriedListingIds = cart.map(c => c.listingId);

  if (queriedListingIds.length === 0) {
    return Promise.resolve({ itemsByAuthor: {}, totalItems: 0 })
  };


 const variantPrefix = 'listing-card';
 const aspectRatio = 1

  return sdk.listings.query({
    ids: queriedListingIds,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  }).then(response => {
    const denormalizedListings = denormalisedResponseEntities(response);
    const listingMap = new Map(denormalizedListings.map(listing => [listing.id.uuid, listing]));

    const updatedCart = cart.map(cartItem => {
      const listing = listingMap.get(cartItem.listingId);
      const prices = listing?.attributes?.publicData?.prices || {};
      if (prices[cartItem.package]) {
        return {
          ...cartItem,
          listing,
        }
      } else {
        return null
      }
    }).filter(Boolean);

    const itemsByAuthor = updatedCart.reduce((acc, item) => {
      const { authorId } = item;
      acc[authorId] = acc[authorId] || [];
      acc[authorId].push(item);
      return acc;
    }, {});

    return { itemsByAuthor, totalItems: updatedCart.length };
  })
}


// Action Types
export const SET_ITEMS_REQUEST = 'app/cart/SET_ITEMS_REQUEST';
export const SET_ITEMS_SUCCESS = 'app/cart/SET_ITEMS_SUCCESS';
export const SET_ITEMS_ERROR = 'app/cart/SET_ITEMS_ERROR';

export const ADD_TO_CART_REQUEST = 'app/cart/ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'app/cart/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'app/cart/ADD_TO_CART_ERROR';

export const CLEAR_NOTIFICATION = 'app/cart/CLEAR_NOTIFICATION';

export const UPDATE_CART_REQUEST = 'app/cart/UPDATE_CART_REQUEST';
export const UPDATE_CART_SUCCESS = 'app/cart/UPDATE_CART_SUCCESS';
export const UPDATE_CART_ERROR = 'app/cart/UPDATE_CART_ERROR';

export const REMOVE_CART_ITEM_REQUEST = 'app/cart/REMOVE_CART_ITEM_REQUEST';
export const REMOVE_CART_ITEM_SUCCESS = 'app/cart/REMOVE_CART_ITEM_SUCCESS';
export const REMOVE_CART_ITEM_ERROR = 'app/cart/REMOVE_CART_ITEM_ERROR';

export const CLEAR_CART_ITEM_REQUEST = 'app/cart/CLEAR_CART_ITEM_REQUEST';
export const CLEAR_CART_ITEM_SUCCESS = 'app/cart/CLEAR_CART_ITEM_SUCCESS';
export const CLEAR_CART_ITEM_ERROR = 'app/cart/CLEAR_CART_ITEM_ERROR';

// Reducer

const initialState = {
  items: null,
  itemsCount: 0,
  setItemsInProgress: false,
  setItemsError: null,
  addToCartInProgress: false,
  addToCartError: null,
  notification: null,
  updateCartInProgress: false,
  updateCartError: null,
  removeFromCartInProgress: false,
  removeFromCartError: null,
  removableItemId: null,
  clearCartItemInProgress: false,
  clearCartItemError: null,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ITEMS_REQUEST:
      return { ...state, setItemsInProgress: true, setItemsError: null };
    case SET_ITEMS_SUCCESS:
      return {
        ...state,
        setItemsInProgress: false,
        items: payload.items,
        itemsCount: payload.itemsCount,
      };
    case SET_ITEMS_ERROR:
      return { ...state, setItemsInProgress: false, setItemsError: payload };
    case ADD_TO_CART_REQUEST:
      return { ...state, addToCartError: null, addToCartInProgress: true };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        addToCartInProgress: false,
        notification: 'Rental added to cart',
        items: payload.items,
        itemsCount: payload.itemsCount,
      };
    case ADD_TO_CART_ERROR:
      return {
        ...state,
        addToCartInProgress: false,
        addToCartError: payload,
        notification: 'Failed to add item to cart!',
      };
    case CLEAR_NOTIFICATION:
      return { ...state, notification: null };
    case UPDATE_CART_REQUEST:
      return { ...state, updateCartError: null, updateCartInProgress: true };
    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        updateCartInProgress: false,
        items: payload.items,
        itemsCount: payload.itemsCount,
      };
    case UPDATE_CART_ERROR:
      return {
        ...state,
        updateCartInProgress: false,
        updateCartError: payload,
      };
    case REMOVE_CART_ITEM_REQUEST:
      return {
        ...state,
        removeFromCartInProgress: true,
        removeFromCartError: null,
        removableItemId: payload,
      };
    case REMOVE_CART_ITEM_SUCCESS:
      return {
        ...state,
        removeFromCartInProgress: false,
        items: payload.items,
        itemsCount: payload.itemsCount,
      };
    case REMOVE_CART_ITEM_ERROR:
      return {
        ...state,
        removeFromCartInProgress: false,
        removeFromCartError: payload,
      };
    case CLEAR_CART_ITEM_REQUEST:
      return {
        ...state,
        clearCartItemInProgress: true,
        clearCartItemError: null,
      };
    case CLEAR_CART_ITEM_SUCCESS:
      return {
        ...state,
        clearCartItemInProgress: false,
        items: payload.items,
        itemsCount: payload.itemsCount,
      };
    case CLEAR_CART_ITEM_ERROR:
      return {
        ...state,
        clearCartItemInProgress: false,
        clearCartItemError: payload,
      };
    default:
      return state;
  }
}

// Action Creators
const setItemsRequest = () => ({ type: SET_ITEMS_REQUEST });
const setItemsSuccess = (items, itemsCount) => ({
  type: SET_ITEMS_SUCCESS,
  payload: { items, itemsCount },
});
const setItemsError = err => ({ type: SET_ITEMS_ERROR, payload: err });

const addToCartRequest = () => ({ type: ADD_TO_CART_REQUEST });
const addTocartSuccess = (items, itemsCount, additionalData) => ({
  type: ADD_TO_CART_SUCCESS,
  payload: { items, itemsCount, additionalData },
});
const addToCartError = err => ({ type: ADD_TO_CART_ERROR, payload: err });

const removeFromCartRequest = itemId => ({
  type: REMOVE_CART_ITEM_REQUEST,
  payload: itemId,
});
const removeFromCartSuccess = (items, itemsCount) => ({
  type: REMOVE_CART_ITEM_SUCCESS,
  payload: { items, itemsCount },
});
const removeFromCartError = err => ({
  type: REMOVE_CART_ITEM_ERROR,
  payload: err,
});

const clearNotification = () => ({ type: CLEAR_NOTIFICATION });

export const updateCartRequest = () => ({
  type: UPDATE_CART_REQUEST,
});

export const updateCartSuccess = (items, itemsCount) => {
  return {
    type: UPDATE_CART_SUCCESS,
    payload: { items, itemsCount },
  };
};

export const updateCartError = error => ({
  type: UPDATE_CART_ERROR,
  error,
});

export const clearCartItemRequest = () => ({ type: CLEAR_CART_ITEM_REQUEST });
export const clearCartItemSuccess = (items, itemsCount) => ({
  type: CLEAR_CART_ITEM_SUCCESS,
  payload: { items, itemsCount },
});
export const clearCartItemError = err => ({
  type: CLEAR_CART_ITEM_ERROR,
  payload: err,
});

// Thunks
export const fetchCartItems = () => (dispatch, getState, sdk) => {
  dispatch(setItemsRequest());
  const { currentUser } = getState().user;
  const cart = currentUser?.attributes?.profile?.publicData?.cart || [];
  getItemsByAuthor(cart, sdk)
    .then(({ itemsByAuthor, totalItems }) => {
      dispatch(setItemsSuccess(itemsByAuthor, totalItems));
    }).catch(err => {
      console.error('Error fetching cart items:', err);
      dispatch(setItemsError(storableError(err)));
    })
};


export const addToCart = (config, params, queryID) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    dispatch(addToCartRequest());
    const {
      selectedPackage,
      bookingDates,
      timeZone,
      listingId,
      authorId,
      quantity = 1, //1 is default
      units,
      usageMultipleValues,
      otherOptionalFees
    } = params;
    
    const { startDate, endDate } = bookingDates || {};

    const userRes = await sdk.currentUser.show();
    const currentUser = userRes.data.data;

    const userTokenMaybe =
      currentUser && currentUser.id ? { userToken: currentUser.id.uuid } : {};

    // Check for required data
    if (
      !selectedPackage ||
      !startDate ||
      !endDate ||
      !listingId ||
      !authorId ||
      !currentUser?.id ||
      !timeZone ||
      !units
    ) {
      throw new Error('Required data is missing');
    }

    const { publicData } = currentUser.attributes.profile;
    const { cart = [] } = publicData || {};

    const itemIndex = cart.findIndex(i => {
      const { startDate, endDate } = i.bookingDates;
      const { startDate: currentStart, endDate: currentEnd } = bookingDates;

      return (
        i.listingId === listingId &&
        moment(startDate).isSame(currentStart) &&
        moment(endDate).isSame(currentEnd) &&
        i.package === selectedPackage
      );
    });

    // Create a shallow copy of the cart array  
    let updatedCart = [...cart];

    if (itemIndex !== -1) {
      // If the item exists in the cart, update its quantity
      updatedCart[itemIndex] = {
        ...cart[itemIndex],
        quantity: Number(cart[itemIndex].quantity || 1) + Number(quantity),
      };
    } else {
      // If the item doesn't exist, add it to the cart
      updatedCart.push({
        id: uuidv4(),
        authorId,
        listingId,
        package: selectedPackage,
        bookingDates,
        quantity: Number(quantity),
        timeZone,
        units: Number(units),
        usageMultipleValues,
        otherOptionalFees
       });
    }

    // Update the current user's profile with the new cart data
    const addRes = await sdk.currentUser.updateProfile(
      {
        publicData: {
          cart: updatedCart,
        },
      },
      { expand: true }
    );

    // Update local cart state.
    const { itemsByAuthor, totalItems } = await getItemsByAuthor(updatedCart, sdk)

    dispatch(
      addTocartSuccess(itemsByAuthor, totalItems, {
        listingId,
        queryID,
        ...userTokenMaybe,
      })
    );

    setTimeout(() => {
      dispatch(clearNotification());
    }, 2000);

    return addRes.data.data;
  } catch (err) {
    dispatch(addToCartError(storableError(err)));
    setTimeout(() => {
      dispatch(clearNotification());
    }, 2000);
  }
};

export const removeFromCart = itemId => async (dispatch, getState, sdk) => {
  try {
    dispatch(removeFromCartRequest(itemId));
    const items = getState().cart.items;
    const updatedItems = {};
    for (let key in items) {
      const item = items[key] || [];
      const filteredItems = item.filter(i => i.id !== itemId);
      if (filteredItems.length) {
        updatedItems[key] = filteredItems;
      }
    }

    const storableItems = [];

    for (let key in updatedItems) {
      const updatedItem = updatedItems[key];
      updatedItem.forEach(i => {
        const { id, bookingDates, listing, package: selectedPackage, quantity, timeZone, units } = i;
        const authorId = listing.author.id.uuid;
        const listingId = listing.id.uuid;

        storableItems.push({
          id,
          authorId,
          bookingDates,
          listingId,
          package: selectedPackage,
          quantity,
          timeZone,
          units,
        });
      });
    }

    // console.log(updatedItems, '&&& &&& => updatedItems');
    // console.log(storableItems, '&&& &&& => storableItems');

    await sdk.currentUser.updateProfile({
      publicData: {
        cart: storableItems,
      },
    });
    const itemsCount = storableItems.length;
    dispatch(removeFromCartSuccess(updatedItems, itemsCount));
    return updatedItems;
  } catch (error) {
    dispatch(removeFromCartError(storableError(error)));
  }
};

export const updateCart = params => async (dispatch, getState, sdk) => {
  try {
    dispatch(updateCartRequest());
    const { id, quantity, bookingDates } = params;
    const quantityMaybe = quantity || quantity == 0 ? { quantity } : {};
    const bookingDatesMaybe =
      bookingDates && bookingDates.startDate && bookingDates.endDate
        ? { bookingDates }
        : {};

    if (!id) {
      throw new Error('cart item id is missing!');
    }

    // stores contains cart items
    const stores = getState().cart.items;
    const updatedStore = {};

    for (let key in stores) {
      const items = stores[key];
      const updatedItems = items.map(i =>
        i.id === id
          ? { ...i, ...quantityMaybe, ...bookingDatesMaybe }
          : { ...i }
      );
      const filteredItems = updatedItems.filter(i => Number(i.quantity));
      if (filteredItems.length) {
        updatedStore[key] = filteredItems;
      }
    }

    const storableItems = [];
    for (let key in updatedStore) {
      const updatedItem = updatedStore[key];
      updatedItem.forEach(i => {
        const { id, bookingDates, listing, package: selectedPackage, quantity, timeZone, units } = i;
        const authorId = listing.author.id.uuid;
        const listingId = listing.id.uuid;

        storableItems.push({
          id,
          authorId,
          bookingDates,
          listingId,
          package: selectedPackage,
          quantity,
          timeZone,
          units,
        });
      });
    }

    // console.log(updatedStore, '&&& &&& => updatedStore');
    // console.log(storableItems, '&&& &&& => storableItems');

    await sdk.currentUser.updateProfile({
      publicData: {
        cart: storableItems,
      },
    });

    const itemsCount = storableItems.length;
    dispatch(updateCartSuccess(updatedStore, itemsCount));
  } catch (err) {
    dispatch(updateCartError(storableError(err)));
  }

  // setTimeout(() => {
  // dispatch(clearNotification())
  // }, 1500);
};

// When someone checkout with multiple items
// which belongs to a particular author remove
// the items after successful checkout.
export const clearCartItem = authorId => async (dispatch, getState, sdk) => {
  try {
    dispatch(clearCartItemRequest());
    const currentCartStores = getState().cart.items;
    const updatedCartStores = { ...currentCartStores };

    delete updatedCartStores[authorId];

    const storableItems = [];
    for (let key in updatedCartStores) {
      const updatedItem = updatedCartStores[key];
      updatedItem.forEach(i => {
        const { id, bookingDates, listing, package: selectedPackage, quantity, timeZone, units } = i;
        const authorId = listing.author.id.uuid;
        const listingId = listing.id.uuid;

        storableItems.push({
          id,
          authorId,
          bookingDates,
          listingId,
          package:selectedPackage,
          quantity,
          timeZone,
          units,
        });
      });
    }

    await sdk.currentUser.updateProfile({
      publicData: {
        cart: storableItems,
      },
    });

    const itemsCount = storableItems.length;
    dispatch(clearCartItemSuccess(updatedCartStores, itemsCount));
  } catch (err) {
    dispatch(clearCartItemError(storableError(err)));
  }
};
