import { LOCATION_CHANGED } from '../ducks/routing.duck';
import { LOGIN_SUCCESS, SIGNUP_SUCCESS } from '../ducks/auth.duck';
import { SHOW_LISTING_SUCCESS } from '../containers/ListingPage/ListingPage.duck';
import { INITIATE_ORDER_SUCCESS, SET_INITIAL_VALUES as SET_CHECKOUT_INITIAL_VALUES } from '../containers/CheckoutPage/CheckoutPage.duck';
import { SAVE_PAYMENT_METHOD_SUCCESS } from '../ducks/paymentMethods.duck';
import { BEGIN_LISTING_CREATION, CREATE_LISTING_DRAFT_SUCCESS, PUBLISH_LISTING_SUCCESS, SAVE_PAYOUT_DETAILS_SUCCESS as SAVE_PAYOUT_DETAILS_SUCCESS_EDIT_LISTING, SUBMIT_AVAILABILITY, SUBMIT_LOCATION, SUBMIT_PRICING } from '../containers/EditListingPage/EditListingPage.duck';
import {
  UPLOAD_IMAGE_SUCCESS as PROFILE_SETTINGS_IMAGE_UPLOAD_SUCCESS,
  PROFILE_SETTINGS_SAVED_NAME,
  PROFILE_SETTINGS_SAVED_PHONE,
  PROFILE_SETTINGS_SAVED_ADDRESS,
  PROFILE_SETTINGS_SAVED_BIO
} from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { SAVE_PAYOUT_DETAILS_SUCCESS as SAVE_PAYOUT_DETAILS_SUCCESS_PAYOUT_PAGE } from '../containers/StripePayoutPage/StripePayoutPage.duck';
import { ADD_TO_CART_SUCCESS } from '../ducks/cart.duck';
import { subUnitDivisors } from '../config/settingsCurrency';
import {
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_ACCESSORY_FEE,
  LINE_ITEM_PICKUP_FEE,
  LINE_ITEM_SHIPPING_FEE,
  LINE_ITEM_CLEANING_FEE,
  LINE_ITEM_CONSUMABLES_FEE,
  LINE_ITEM_DELIVERY_FEE,
  LINE_ITEM_ENVIRONMENTAL_FEE,
  LINE_ITEM_FUEL_FEE,
  LINE_ITEM_SETUP_FEE,
  LINE_ITEM_USAGE_FEE,
} from '../util/types';

const commissionLineItemCodes = [LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROVIDER_COMMISSION];
const feeLineItemCodes = [
  LINE_ITEM_ACCESSORY_FEE,
  LINE_ITEM_CLEANING_FEE,
  LINE_ITEM_CONSUMABLES_FEE,
  LINE_ITEM_DELIVERY_FEE,
  LINE_ITEM_ENVIRONMENTAL_FEE,
  LINE_ITEM_FUEL_FEE,
  LINE_ITEM_SETUP_FEE,
  LINE_ITEM_USAGE_FEE,
  LINE_ITEM_SHIPPING_FEE,
  LINE_ITEM_PICKUP_FEE
];

const excludableLineItems = [...commissionLineItemCodes, ...feeLineItemCodes];

/**
 * As most of the Online Sale events require currency, value, and items,
 * this is a helper function to get them from a listing.
 * @param {object} listing 
 * @param {number} quantity
 * @returns {object} object containing necessary params.
 */
const getRequiredParamsFromListing = (listing, quantity = 1, selectedPackage = '') => {
  const { id, attributes } = listing || {};
  const { title, price: defaultPrice, publicData } = attributes || {};
  const { category, prices = {} } = publicData || {};

  const price = prices[selectedPackage] || defaultPrice;
  const unitDivisor = subUnitDivisors[price?.currency];
  const value = !!price ? (price.amount / unitDivisor) * quantity : null;
  const currency = !!price ? price.currency : null;
  const items = !!id && !!title && !!category && !!price
    ? [{ item_id: id.uuid, item_name: title, item_category: category, price: (price.amount / unitDivisor), quantity }]
    : [];

  return {
    items,
    value,
    currency
  };
};

const getRequiredParamsFromOrderData = (orderData, lineItems) => {
  const itemsLineItems = lineItems?.filter(i => !excludableLineItems.includes(i.code));

  const items = itemsLineItems && itemsLineItems.length
    ? itemsLineItems.map(i => {
      const { code, seats = 1, units = 1 } = i;
      const unitPrice = i.unitPrice;
      const unitDivisor = subUnitDivisors[unitPrice.currency];
      const price = unitPrice.amount / unitDivisor;
      const quantity = seats * units;

      const itemIndex = +code.match(/\d+/)[0] - 1;
      const item = orderData[itemIndex];

      if (item) {
        const item_id = item.listing.id.uuid;
        const item_name = item.listing.attributes.title;
        const { category: item_category } = item.listing.attributes.publicData;

        return {
          item_id,
          item_name,
          item_category,
          price,
          quantity,
        }
      }
      return {};
    })
    : [];

  const value = items.reduce((acc, curr) => acc += (curr.price * curr.quantity), 0);
  const currency = itemsLineItems && itemsLineItems.length ? itemsLineItems[0].unitPrice.currency : null;

  // Items, value, currency
  return { value, items, currency }
};

const getRequiredParamsFromStoredLineItems = (storedLineItems) => {
  const itemsLineItems = storedLineItems.filter(i => !commissionLineItemCodes.includes(i.code));
  const items = itemsLineItems && itemsLineItems.length
    ? itemsLineItems.map(i => {
      const { seats = 1, units = 1 } = i;
      const price = i.unitPrice.amount;
      const quantity = seats * units;

      return {
        item_id: i.itemListingId,
        item_name: i.title,
        item_category: i.itemListingCategory,
        price,
        quantity
      }
    }) : [];

  const value = items.reduce((acc, curr) => acc += (curr.price * curr.quantity), 0);
  const currency = itemsLineItems && itemsLineItems.length ? itemsLineItems[0].unitPrice.currency : null;
  return { value, items, currency };
};


// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
// - trackPageView(canonicalPath, previousPath): called when the URL is changed
export const createMiddleware = handlers => store => next => action => {
  const { type, payload } = action;

  // Page view
  if (type === LOCATION_CHANGED) {
    const previousPath = store?.getState()?.routing?.currentCanonicalPath;
    const { canonicalPath } = payload;
    handlers.forEach(handler => {
      handler.trackPageView(canonicalPath, previousPath);
    });
  };

  // Login
  if (type === LOGIN_SUCCESS) {
    handlers.forEach(handler => {
      handler.trackLogin();
    })
  };


  // Sign up
  if (type === SIGNUP_SUCCESS) {
    handlers.forEach(handler => {
      handler.trackSignup(payload);
    });
  };

  // View Item
  if (type === SHOW_LISTING_SUCCESS) {
    // trigger view_item event that requires:
    // currency, value, items ([{item_id, item_name, item_category}])
    const listing = payload;
    const requiredParams = getRequiredParamsFromListing(listing);
    handlers.forEach((handler) => {
      handler.trackViewItem(requiredParams);
    });
  };

  // Add to cart
  if (type === ADD_TO_CART_SUCCESS) {
    const { listingId, userToken, queryID } = payload?.additionalData;

    handlers.forEach((handler) => {
      // add_to_cart Algolia
      handler.trackAddToCartAlgo(listingId, userToken, queryID);
    });
  }

  // Events: Add to cart/ Begin Checkout
  // Both events are together because there is no
  // add to cart functionality in this site.
  if (type === SET_CHECKOUT_INITIAL_VALUES) {
    // currency, value, items ([{item_id, item_name}])

    const { lineItems = [], orderData } = payload || {};
    const requiredParams = getRequiredParamsFromOrderData(orderData, lineItems)

    handlers.forEach((handler) => {
      // add_to_cart
      handler.trackAddToCart(requiredParams);

      // begin_checkout
      handler.trackBeginCheckout(requiredParams);
    });
  };



  // if (type === SAVE_PAYMENT_METHOD_SUCCESS) {
  //   const listing = payload;
  //   const requiredParams = getRequiredParamsFromListing(listing);
  //   handlers.forEach(handler => {
  //     handler.trackAddPaymentInfo(requiredParams);
  //   })
  // };


  //Events: Add shipping info/Add payment info/Purchase 
  // Keeping all the three events under the same condition to trigger them
  // in a sequence that "checkout journey" report requires. In our sharetribe
  // payment is saved after the purchase action is completed (action 5 in handle submit)
  // but the "checkout report" requires it to be before purchase. So, doing this.
  if (type === INITIATE_ORDER_SUCCESS) {
    // { transaction_id, currency, value, items }'
    const transaction_id = payload?.order?.id?.uuid;
    const storedLineItems = payload?.order?.attributes?.metadata?.lineItems;

    const otherRequiredParams = getRequiredParamsFromStoredLineItems(storedLineItems);


    handlers.forEach(handler => {
      // add_shipping_info
      handler.trackAddShippingInfo(otherRequiredParams);

      // add_payment_info
      handler.trackAddPaymentInfo(otherRequiredParams);

      // purchase
      handler.trackPurchase({
        transaction_id,
        purchaseCount: payload.count,
        ...otherRequiredParams
      })
    });
  };

  // Begin Listing creation
  if (type === BEGIN_LISTING_CREATION) {
    handlers.forEach(handler => {
      handler.trackBeginListingCreation();
    })
  };

  if (type === CREATE_LISTING_DRAFT_SUCCESS) {
    handlers.forEach(handler => {
      handler.trackSubmitDetails();
    })
  };

  // Submit location
  if (type === SUBMIT_LOCATION) {
    handlers.forEach(handler => {
      handler.trackSubmitLocation();
    })
  };

  // Submit pricing
  if (type === SUBMIT_PRICING) {
    handlers.forEach(handler => {
      handler.trackSubmitPricing();
    })
  };

  // Submit availability
  if (type === SUBMIT_AVAILABILITY) {
    handlers.forEach(handler => {
      handler.trackSubmitAvailability();
    })
  };

  // Publish Listing
  if (type === PUBLISH_LISTING_SUCCESS) {
    handlers.forEach(handler => {
      handler.trackPublishListing();
    })
  };

  if (type === PROFILE_SETTINGS_IMAGE_UPLOAD_SUCCESS) {
    handlers.forEach(handler => handler.trackProfileSettingsSavedPhoto())
  };

  if (type === PROFILE_SETTINGS_SAVED_NAME) {
    handlers.forEach(handler => handler.trackProfileSettingsSavedName())
  };

  if (type === PROFILE_SETTINGS_SAVED_PHONE) {
    handlers.forEach(handler => handler.trackProfileSettingsSavedPhone())
  };

  if (type === PROFILE_SETTINGS_SAVED_ADDRESS) {
    handlers.forEach(handler => handler.trackProfileSettingsSavedAddress())
  };

  if (type === PROFILE_SETTINGS_SAVED_BIO) {
    handlers.forEach(handler => handler.trackProfileSettingsSavedBio())
  };

  if (type === SAVE_PAYOUT_DETAILS_SUCCESS_EDIT_LISTING
    || type === SAVE_PAYOUT_DETAILS_SUCCESS_PAYOUT_PAGE) {

    handlers.forEach(handler => handler.trackAccountSettingsSavedPaymentInfo())
  };

  if (type === SAVE_PAYMENT_METHOD_SUCCESS) {
    handlers.forEach(handler => handler.trackAccountSettingsSavedPaymentMethod())
  };

  next(action);
};
