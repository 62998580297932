import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useGeoSearch } from 'react-instantsearch';
import { types as sdkTypes } from '../../util/sdkLoader';
const { LatLngBounds, LatLng } = sdkTypes;

import IconClose from '../IconClose/IconClose';
import css from './AlgoliaLocationSearch.module.css';
import classNames from 'classnames';

const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const PREDICTIONS_KEY = 'predictions';
const MAX_PREDICTIONS = 3;
const DEFAULT_LOCATION = 'Ottawa';



/**
 * store prediction to local host
 * @param {*} data
 */
const storePredictions = prediction => {
  if (!prediction || !prediction.id || typeof window === 'undefined') {
    return;
  }

  const storedPredictions = window.localStorage.getItem(PREDICTIONS_KEY);
  const parsedPredictions = storedPredictions
    ? JSON.parse(storedPredictions)
    : [];

  // Use a Map for uniqueness and easy manipulation
  const predictionsMap = new Map(
    parsedPredictions.map(pred => [pred.id, pred])
  );

  // Add new prediction at the beginning
  predictionsMap.delete(prediction.id); // Ensure no duplicates
  const newPredictions = [prediction, ...Array.from(predictionsMap.values())];

  // Keep only the first MAX_PREDICTIONS entries
  const limitedPredictions = newPredictions.slice(0, MAX_PREDICTIONS);

  // Store updated predictions in localStorage
  window.localStorage.setItem(
    PREDICTIONS_KEY,
    JSON.stringify(limitedPredictions)
  );
};

/**
 * Get predictions from the local storage
 * @returns predictions array
 */
const getPredictions = () => {
  if (typeof window === 'undefined') {
    return [];
  }

  const storedPredictions = window.localStorage.getItem(PREDICTIONS_KEY);
  return storedPredictions ? JSON.parse(storedPredictions) : [];
};

const LocationSearch = props => {
  const {
    onBoundsChange,
    defaultLocation,
    currentLocationRefinement,
    clearMapRefinement,
    name,
    initialValues,
    onRefineLocation,
    refineLocation,
    className
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [error, setError] = useState('');
  const [inputFocused, setInputFocused] = useState(true);
  const blurTimeout = useRef(null);
  const dropdownRef = useRef(null);

  const storedPredictions = getPredictions();
  const renderablePredictions =
    predictions && predictions.length > 0
      ? predictions
      : storedPredictions && storedPredictions.length > 0
        ? storedPredictions
        : [];

  // Show predictions only if input is in focused.
  const showPredictions = inputFocused;

  const { bounds: qBounds, address: qAddress } = initialValues || {};
  const { city, origin } = defaultLocation || {};

  // set the initial state
  useEffect(() => {
    let address = null;
    let bounds = null;

    if (qBounds instanceof LatLngBounds && !!qAddress) {
      address = qAddress;

      // normalise the bounds
      bounds = {
        southWest: {
          lat: qBounds.sw.lat,
          lng: qBounds.sw.lng,
        },
        northEast: {
          lat: qBounds.ne.lat,
          lng: qBounds.ne.lng,
        },
      };
    } else {
      address = city || DEFAULT_LOCATION;
      // Create 50km radius bounds.
      const { lng, lat } = origin || {};
      const kmToDeg = 50 / 111; //1 deg is approximately equal to 111km.
      const center = lng && lat ? [lng, lat] : [-75.6972, 45.4215]; // Ottawa's center should be fallback center
      bounds = {
        southWest: {
          lng: center[0] - kmToDeg,
          lat: center[1] - kmToDeg,
        },
        northEast: {
          lng: center[0] + kmToDeg,
          lat: center[1] + kmToDeg,
        },
      };
    }

    // Clear the current refinement
    if (currentLocationRefinement) {
      clearMapRefinement();
    }
    
    setInputValue(address);
    setInputFocused(false);
    refineLocation(bounds);

  }, [qAddress, city]);

  useEffect(() => {
    if (inputValue) {
      fetchPredictions(inputValue);
    } else {
      setPredictions([]);
    }
  }, [inputValue]);

  const fetchPredictions = async query => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${mapboxToken}`
      );
      setPredictions(response.data.features);
    } catch (error) {
      console.error('Error fetching predictions:', error);
    }
  };

  const handleInputChange = e => {
    setInputValue(e.target.value);

    setError('');
  };

  const handleInputBlur = () => {
    // Delay input blur for some time so
    // user can select value from the recommendations.
    blurTimeout.current = setTimeout(() => {
      // if (!selectedPrediction) {
      //   setError('Please select from the suggested locations');
      // }
      setInputFocused(false);
    }, 500);
  };

  const handlePredictionClick = prediction => {
    if (blurTimeout && blurTimeout.current) {
      clearTimeout(blurTimeout.current);
    }

    const { bbox = [], center = [] } = prediction || {};

    // swLng (southwest longitude), swLat (southwest latitude)
    // neLng (northeast longitude), neLat (northeast latitude)
    const [swLng, swLat, neLng, neLat] = bbox;

    const kmToDegLat = 50 / 111; // Latitude degree conversion
    const kmToDegLng = 50 / (111 * Math.cos((swLat + neLat) / 2 * Math.PI / 180)); // Longitude degree conversion

    // Expand the bbox by 50km
    const expandedBounds = bbox && bbox.length === 4
      ? [
          swLng - kmToDegLng, // Expanded swLng
          swLat - kmToDegLat, // Expanded swLat
          neLng + kmToDegLng, // Expanded neLng
          neLat + kmToDegLat  // Expanded neLat
        ]
      : null;

    // Construct bounds around center if bbox is missing
    const constructedBounds =
      center && center.length === 2
        ? [
          center[0] - kmToDegLng, // swLng
          center[1] - kmToDegLat, // swLat
          center[0] + kmToDegLng, // neLng
          center[1] + kmToDegLat, // neLat
        ]
        : null;

    let selectedBounds =
      expandedBounds
        ? {
            southWest: { lng: expandedBounds[0], lat: expandedBounds[1] },
            northEast: { lng: expandedBounds[2], lat: expandedBounds[3] },
          }
        : constructedBounds
          ? {
              southWest: { lng: constructedBounds[0], lat: constructedBounds[1] },
              northEast: { lng: constructedBounds[2], lat: constructedBounds[3] },
            }
          : null;

    if (prediction && selectedBounds) {
      setInputValue(prediction.place_name);
      setError('');
      setInputFocused(false);

      //   Make query to algolia with bounds
      //   First clear any refinement.
      if (currentLocationRefinement) {
        clearMapRefinement();
      }

      const { southWest, northEast } = selectedBounds;
      const swLatLng = new LatLng(southWest.lat, southWest.lng);
      const neLatLng = new LatLng(northEast.lat, northEast.lng);
      const latlngBounds = new LatLngBounds(neLatLng, swLatLng);

      //   Call on change
      //   refineItems(selectedBounds)
      onRefineLocation({
        address: prediction.place_name,
        bounds: latlngBounds,
      });

      // Pass the bounds to the parent component
      // to show on map. The Map component expects
      // bounds in LatLngBounds format.
      // onBoundsChange(latlngBounds);

      // Store prediction to local
      storePredictions(prediction);
    }
  };


  const handleMouseEnter = index => {
    const items = dropdownRef.current.querySelectorAll('li');
    items.forEach((item, idx) => {
      item.classList.toggle(css.highlighted, idx === index);
    });
  };

  return (
    <div className={classNames(className, css.container)}>
      <div className={css.inputWrapper}>
        <input
          type="text"
          name={name}
          value={inputValue}
          onFocus={() => setInputFocused(true)}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          className={css.input}
          placeholder="Enter a location"
        />
        {!!inputValue ? (
          <button
            className={css.clearLocation}
            type="button"
            onClick={() => {
              setInputValue('');

              setError('');
            }}
          >
            <IconClose size="small" />
          </button>
        ) : null}
      </div>
      {error && <div className={css.error}>{error}</div>}
      {renderablePredictions.length > 0 && showPredictions && (
        <ul className={css.dropdown} ref={dropdownRef}>
          {renderablePredictions.map((prediction, index) => (
            <li
              key={prediction.id}
              onClick={() => handlePredictionClick(prediction)}
              onMouseEnter={() => handleMouseEnter(index)}
              className={css.dropdownItem}
            >
              {prediction.place_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationSearch;
